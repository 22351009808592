import { useState, useEffect } from 'react';
import TableRow from './TableRow';
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux';
import { moduleName } from '../../redux/modules/apartment-search';
import axios from 'axios';

const SimilarRows = ({api, similar, checkData, values, types}) => {
  const router = useRouter();
  const [data, setData] = useState(null);
  useEffect(() => {
    const fd = new FormData();
    fd.append('similar', similar);
    fd.append('view', '0');
    fd.append('catalog', router.query.slug ? router.query.slug[0] : 'catalog_new');

    axios
      .post(api + '/ajax/json.php?page=apartment-search-data', fd)
      .then(({ data }) => data)
      .then(response => {
        setData(response.list)
      })
      .catch(error => {
        console.log(error)
      })
  },[]);

  if(!data) return null;

  return(
    <>
      <tr className="apartment-search-similar__row">
        <td colSpan="7">
          <div className="apartment-search-similar__title">
            <div>Похожие квартиры</div>
          </div>
        </td>
      </tr>
      {data.map(item => <TableRow key={`item${item.id}`} data={item} api={api} hideSimilar checkData={checkData} values={values} types={types} />)}
    </>
  )
}

export default SimilarRows;