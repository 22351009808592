import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router'
import Item from './Item';
import ListItemMoble from './ListItemMoble';

const SimilarItems = ({api, similar, active, values, types}) => {
  const router = useRouter();
  const mobile = useSelector(state => state.mobile);
  const [data, setData] = useState(null);
  useEffect(() => {
    const fd = new FormData();
    fd.append('similar', similar);
    fd.append('view', '0');
    fd.append('catalog', router.query.slug ? router.query.slug[0] : 'catalog_new');

    axios
      .post(api + '/ajax/json.php?page=apartment-search-data', fd)
      .then(({ data }) => data)
      .then(response => {
        setData(response.list)
      })
      .catch(error => {
        console.log(error)
      })
  },[]);

  if(!data) return null;

  return(
    <div className="apartment-search-similar fade-in">
      <div className="apartment-search-similar__title">
        <div>Похожие квартиры</div>
      </div>
      <div className="apartment-search__list">
        {!active && data.map(item => <Item key={`item${item.id}`} data={item} api={api} slide values={values} types={types} />)}
        {(mobile && active) && data.map(item => <Item key={`item${item.id}`} data={item} api={api} slide values={values} types={types} />)}
        {/*(mobile && active) && data.map(item => <ListItemMoble key={`item${item.id}`} data={item} api={api} slide />)*/}
      </div>
    </div>
  )
}

export default SimilarItems;