import { useRouter } from 'next/router'
import Link from '../_app/link'

const Breadcrumbs = ({ api, items }) => {
  if(!items || items.length === 0) return null;

  const router = useRouter();

  const namesEmoji = [
    '/catalog_cre',
    '/catalog_new',
    '/catalog_country',
    '/recommended'
  ];
  const listBC = [];
  const listMicro = [];

  items.map((el, key) => {
    const link = el.link || router.asPath;
    listMicro.push({
      "@type": "ListItem",
      "position": key + 1,
      "name": (namesEmoji.includes(link) ? '&#128308; ' : '') + el.name,
      "item": api + link
    });
    listBC.push(<>
      {key > 0 ? ' — ' : ''}
      {el.link
        ? <Link key={key} to={el.link} className="no-border-link"><span>{el.name}</span></Link>
        : <span key={key}>{el.name}</span>
      }
    </>);
  });

  const contentMicro = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": [...listMicro]
  };

  return (<>
    <div className="path">
      {listBC}
    </div>
    <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(contentMicro) }}></script>
  </>)
}

export default Breadcrumbs;
