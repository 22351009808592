import { SvgSelectArrow, SvgNoPhoto } from '../../icons';
import { declOfNum } from '../../utils';
import { useRouter } from 'next/router';
import AddFavorite from  '../_app/AddFavorite';

const TableRow = ({data, api, hideSimilar, setSimilar, similar, checkData, values, types}) => {
  const router = useRouter();
  const format = Intl.NumberFormat('ru-RU');

  const changeSimilar = () => {
    if(!similar){
      setSimilar(data.similar.id);
    }
    else{
      if(similar == data.similar.id){
        setSimilar(null);
      }
      else{
        setSimilar(data.id);
      }
    }
  }

  const createSimilarText = () => {
    if(data.similar && !hideSimilar){
      if(similar && similar === data.id){
        return(
          <div className="apartment-search__item-similar" onClick={changeSimilar}>
            <div>
              Свернуть
              <SvgSelectArrow />
            </div>
          </div>
        )
      }

      return (
        <div className="apartment-search__item-similar" onClick={changeSimilar}>
          <div>
            +{data.similar.count} {declOfNum(data.similar.count, ['похожий','похожих','похожих'])} {declOfNum(data.similar.count, ['вариант','варианта','вариантов'])}
            <SvgSelectArrow />
          </div>
        </div>
      )
    }
  }

  const createDataAttr = () => {
    if(similar && similar === data.id){
      return 'active';
    }
    if(similar && similar !== data.id){
      return 'opacity';
    }
    return '';
  }

  const createParams = data => {
    if(data && types){
      let url = '';

      const fltr = [...types.filters, ...types.extras]

      for(let i in data){
        if(i !== 'type' && i !== 'target'){
          if(Array.isArray(data[i])){
            if(data[i].length){
              const current = fltr.filter(item => item.name == i)[0];
              if(current){
                if(data[i][0] != current.data[0] || data[i][1] != current.data[1]){
                  data[i].forEach(item => {
                    url += i + '=' + item + '&'
                  });
                }
              }
            }
          }
          else{
            if(data[i]){
              url += i + '=' + data[i] + '&'
            }
          }
        }
      }

      url = url.substring(0, url.length - 1);

      return url ? '?' + url : '';
    }
    else{
      return '';
    }
  }

  const to = () => {
    if(data.type == 1){
      const params = createParams(values);
      window.open(`/${router.query.slug ? router.query.slug[0] : 'catalog_new'}/${data.code}${params}`);
    }
    else{
      window.open(`/${router.query.slug ? router.query.slug[0] : 'catalog_new'}/${data.code}/${data.id}`);
    }
  }

  const createCellSize = () => {
    let size = 0;
    if(!!checkData.areaSize){
      size++;
    }
    if(!!checkData.roomsSize){
      size++;
    }
    if(!!checkData.floorSize){
      size++;
    }
    if(!!checkData.priceSize){
      size++;
    }

    return size;
  }

  const checkPlan = () => {
    if(data.plan){
      if(data.plan.length > 0){
        return true;
      }
    }

    return false;
  }

  const createImg = () => {
    const imgs = checkPlan() ? data.plan : data.image;

    if(imgs[0]){
      return (
        <div
          className={!checkPlan() ? 'apartment-search__table-image cover' : 'apartment-search__table-image'}
          style={{ backgroundImage: `url('${api}${imgs[0]}')`}}
        />
      )
    }
    
    return(
      <div className="apartment-search__table-image-empty"><SvgNoPhoto /></div>
    )
  }

  const createNameAddr = () => {
    if(data.resale){
      return(
        <>
          {data.addr && <div className="apartment-search__item-addr">{data.addr}</div>}
          {data.name && <div className="apartment-search__item-name">{data.name}</div>}
        </>
      )
    }
    else{
      return(
        <>
          {data.name && <div className="apartment-search__item-addr">{data.name}</div>}
          {data.addr && <div className="apartment-search__item-name">{data.addr}</div>}
        </>
      )
    }
  }


  if(data.type == 1){

    return (
      <>
        <tr className="first" data-similar={createDataAttr()}>
          <td rowSpan="2" onClick={to}>
            {createImg()}
          </td>
          <td align="center">
            <div className="apartment-search__item-metro">
              <AddFavorite data={data} type={data.type} api={api} />
            </div>
          </td>
          <td onClick={to}>
            {createNameAddr()}
            {data.metro && <div className="apartment-search__item-name">{data.metro}</div>}
          </td>
          {!!checkData.roomsSize && <td onClick={to} align="center">{data.rooms && Object.values(data.rooms).join(', ')}</td>}
          {!!checkData.priceSize && (<td onClick={to}>
            {!!data.price && <div className="apartment-search__item-price"><div>{typeof data.price == 'number' ? `от ${format.format(data.price)} ₽` : data.price}</div></div>}
            {!!data.rent && <div className="apartment-search__item-price"><div>от {format.format(data.rent)} ₽ / мес</div></div>}
          </td>)}
        </tr>
        <tr className="last" data-similar={createDataAttr()}>
          <td></td>
          <td className="vbottom">
            {!!data.status && <div className="apartment-search__item-status">{data.status}</div>}
          </td>
          {!!createCellSize() && (
            <td className="vbottom" colSpan={createCellSize()}>
              {data.similar && createSimilarText()}
            </td>
          )}
        </tr>
      </>
    )
  }

  return(
    <>
      <tr className="first" data-similar={createDataAttr()}>
        <td rowSpan="2" onClick={to}>
          {createImg()}
        </td>
        <td align="center">
          <div className="apartment-search__item-metro">
          <AddFavorite data={data} type={data.type} api={api} />
          </div>
        </td>
        <td onClick={to}>
          {createNameAddr()}
          {data.metro && <div className="apartment-search__item-name">{data.metro}</div>}
        </td>
        {!!checkData.areaSize && <td onClick={to} align="center">{!!data.area && `${data.area} м²`}</td>}
        {!!checkData.roomsSize && <td onClick={to} align="center">{(!!data.rooms && data.rooms != 0) && data.rooms}</td>}
        {!!checkData.floorSize && <td onClick={to} align="center">{(!!data.floor && data.floor != 0) && `${data.floor}${data.floorTotal ? '/'+data.floorTotal : ''}`}</td>}
        {!!checkData.priceSize && (
          <td onClick={to}>
            {!!data.price && <div className="apartment-search__item-price"><div>{typeof data.price == 'number' ? `${format.format(data.price)} ₽` : data.price}</div>{data.saleText && <span>%</span>}</div>}
            {!!data.rent && <div className="apartment-search__item-price"><div>от {format.format(data.rent)} ₽ / мес</div></div>}
          </td>
        )}
      </tr>
      <tr className="last" data-similar={createDataAttr()}>
        <td></td>
        <td className="vbottom">
          {!!data.status && <div className="apartment-search__item-status">{data.status}</div>}
        </td>
        {createCellSize() && (
          <td className="vbottom" colSpan={createCellSize()}>
            {data.similar && createSimilarText()}
          </td>
        )}
      </tr>
    </>
  )
}

export default TableRow;